import styled from "styled-components";
import { StaticIcon } from "../common/icons";
import { useEffect, useState } from "react";
import { McPopover } from "@maersk-global/mds-react-wrapper";
import {
  getNotifications,
  readNotifications,
} from "../../services/Notifications";
import NotificationItem from "./notificationItem";

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    getAllNotifications();

    const intervalId = setInterval(() => {
      getAllNotifications();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const getAllNotifications = () =>
    getNotifications()
      .then((res) => {
        setNotifications(res);
        let filteredNotifications = res.filter((item: any) => !item.isRead);
        setNotificationCount(filteredNotifications.length);
      })
      .catch((err) => {
        console.log(err);
      });

  const readAllNotifications = () => {
    if (notificationCount > 0) {
      readNotifications()
        .then((res) => {
          if (res) setNotificationCount(0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <McPopover
      position="bottom-left"
      arrow={false}
      fit="large"
      maxwidth="350px"
      maxheight="400px"
      dontadjustheight
      trigger="click"
      preventcloseonblur
    >
      <IconWithBadge slot="trigger">
        <div
          onClick={() => {
            setNotificationCount(0);
            readAllNotifications();
          }}
          style={{ cursor: "pointer" }}
        >
          <StaticIcon icon="bell" size="24" />
          {notificationCount > 0 && (
            <BadgeContainer>
              <BadgeCount>
                {notificationCount > 10 ? "10+" : notificationCount}
              </BadgeCount>
            </BadgeContainer>
          )}
        </div>
      </IconWithBadge>
      {notifications.length > 0 ? (
        notifications.map((notification: any, index: number) => (
          <>
            <NotificationItem notification={notification} />
            {index !== notifications.length - 1 && <hr />}
          </>
        ))
      ) : (
        <div style={{ padding: "20px" }}>
          <p>Its all empty here...</p>
        </div>
      )}
    </McPopover>
  );
}

const IconWithBadge = styled.div`
  position: relative;
  align-items: center;
`;

const BadgeContainer = styled.div`
  position: relative;
`;

const BadgeCount = styled.span`
  position: absolute;
  top: -30px;
  left: -5px;
  padding: 2px 6px;
  background-color: var(--mds_brand_appearance_primary_default_text-color);
  color: var(--mds_brand_appearance_neutral_default_background-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
