import { useEffect, useState } from "react";
import { themeThumbnailLogo } from "../../constants/Themes";

export default function ThemeSelection({
  theme,
  onThemeSelected,
}: Readonly<{
  theme: string;
  onThemeSelected: (theme: string) => void;
}>) {
  const [themeThumbnail, setThemeThumbnail] = useState(themeThumbnailLogo);

  useEffect(() => {
    let modifiedThemeThumbnail = themeThumbnailLogo.map((themeThumbnail) => {
      return {
        ...themeThumbnail,
        selected: themeThumbnail.code === theme,
      };
    });
    setThemeThumbnail(modifiedThemeThumbnail);
  }, [theme]);

  const handleThemeSelection = (theme: string) => {
    let modifiedThemeThumbnail = themeThumbnail.map((themeThumbnail) => {
      return {
        ...themeThumbnail,
        selected: themeThumbnail.code === theme,
      };
    });
    setThemeThumbnail(modifiedThemeThumbnail);
    onThemeSelected(theme);
  };

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        flexDirection: "row",
      }}
    >
      {themeThumbnail.map((themeThumbnail: any) => {
        return (
          <div
            style={{
              flexDirection: "column",
              padding: "0px 10px 10px 10px",
              border: themeThumbnail.selected
                ? "3px solid var(--mds_brand_appearance_primary_default_border-color)"
                : "none",
            }}
            onClick={() => handleThemeSelection(themeThumbnail.code)}
            key={themeThumbnail.code}
          >
            <div>
              <img src={themeThumbnail.img} />
            </div>
            <div>{themeThumbnail.label}</div>
          </div>
        );
      })}
    </div>
  );
}
