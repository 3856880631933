import styled from "styled-components";
import Box from "./box";

export const FlexGrowBox = styled(Box)`
  flex-grow: 1;
`;

export const FilterBox = styled(Box)`
  display: grid;
  gap: 10px;
  padding: 0px 0px 20px 0px;
  align-items: end;
  .actionButtonWrapper {
    gap: 5px;
  }
`;

export const StyledTag = styled.span<{ bgColor: string }>`
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  border: none;
  border-radius: 20px;
  line-height: 20px;
  background-color: ${(props) => props.bgColor};
  color: var(--mds_brand_appearance_neutral_default_text-color);
  padding: 2px 10px;
  font-size: 16px;
  width: auto;
`;
