import { ToastAppearance } from "@maersk-global/mds-components-core/mc-toast/types";
import { McNotification, McToast } from "@maersk-global/mds-react-wrapper";
import ReactDOM from "react-dom/client";
import { v4 as uuidv4 } from "uuid";

/**
 * Displays a toast notification on the screen.
 * 
 * This function is responsible for creating and displaying a toast notification to the user. It leverages the `McToast`
 * component for the notification UI and supports customizing the appearance to indicate different types of messages
 * (e.g., success, error, warning). The toast appears at a predefined position on the screen and automatically
 * disappears after a short duration, providing a non-intrusive way of delivering feedback or information to the user.
 * 
 * Parameters:
 * - `title`: The title of the toast notification, displayed prominently at the top of the toast.
 * - `description`: Detailed information or the message body of the toast, providing additional context to the user.
 * - `appearance`: Specifies the visual style of the toast (e.g., success, error, warning), allowing the toast to
 *   convey the nature of the notification.
 * 
 * Returns:
 * - A `Promise<void>` that resolves once the toast notification has been closed, either automatically after the
 *   timeout or by user interaction.
 * 
 * Implementation Details:
 * - A new `div` element is dynamically created and appended to the document body to serve as the container for the
 *   toast notification.
 * - A unique ID is assigned to the container using the `uuidv4` function to ensure it does not conflict with other
 *   elements in the DOM.
 * - `ReactDOM.createRoot` is used to create a React root for the new container, into which the `McToast` component is
 *   rendered.
 * - The `McToast` component is configured with the provided `title`, `description`, and `appearance` props, and it is
 *   set to automatically close after 4000 milliseconds (4 seconds).
 * - Upon closure of the toast, the container `div` is removed from the document, and the promise is resolved.
 * 
 * Usage:
 * This function can be invoked throughout the application to display toast notifications for various purposes, such
 * as confirming actions taken by the user, indicating the completion of background processes, or warning the user
 * about errors or important notices.
 */
export const toast = (
  title: string,
  description: string,
  appearance: ToastAppearance
): Promise<void> => {
  return new Promise((resolve, _) => {
    const container = document.createElement("div");
    container.setAttribute("id", uuidv4());

    const root = ReactDOM.createRoot(document.body.appendChild(container));

    const element = (
      <McToast
        appearance={appearance}
        open
        position="top-right"
        duration={4000}
        close={() => {
          root.unmount();
          container.remove();
          resolve();
        }}
        style={{ zIndex: 9999 }}
      >
        <McNotification heading={title} body={description} />
      </McToast>
    );

    root.render(element);
  });
};

export const toastError = (description: string, title?: string) => {
  toast(title ?? "Error", description, "error");
};

export const toastSuccess = (description: string, title?: string) => {
  toast(title ?? "Success", description, "success");
};
