import apmtThemeThumbnail from "../assets/apmtThemeThumbnail.png";
import maerskThemeThumbnail from "../assets/maerskThemeThumbnail.png";
import apmTerminalsLogo from "../assets/apmTerminalsLogo.png";
import maerskLogo from "../assets/logo.png";

export const themes = {
  MAERSK: "maersk",
  APMTERMINALS: "apmterminals",
};

export const themeThumbnailLogo = [
  {
    img: maerskThemeThumbnail,
    code: themes.MAERSK,
    label: "MAERSK",
    logo: maerskLogo,
  },
  {
    img: apmtThemeThumbnail,
    code: themes.APMTERMINALS,
    label: "APMT",
    logo: apmTerminalsLogo,
  },
];

export const getLogoForTheme = (theme: string) => {
  let index = themeThumbnailLogo.findIndex((item: any) => {
    return item.code === theme;
  });

  return themeThumbnailLogo[index].logo;
};
