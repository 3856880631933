import { Route, Routes } from "react-router-dom";
import Brands from "../pages/Brands/Brands";
import { TabItemTypes, tabItems } from "../constants/TabItems";
import { Suspense, lazy } from "react";
import { loadingIndicator } from "../components/common/loading";

const Customers = lazy(() => import("../pages/Customers/Customers"));
const Users = lazy(() => import("../pages/UserManagement/Users"));
const TabsConfiguration = lazy(
  () => import("../pages/Configuration/TabsConfiguration")
);
const MasterData = lazy(() => import("../pages/Brands/MasterData"));

export default function GscAdminRoutes() {
  return (
    <Suspense fallback={loadingIndicator}>
      <Routes>
        <Route
          path="/"
          element={
            <Brands
              shouldShowButtons={true}
              shoudShowRegion={true}
              NavigationLink="system-configuration"
              heading="Business Brands"
              subheading="Configure business brands here."
            />
          }
        />
        <Route
          path="/hierarchy"
          element={
            <Brands
              shouldShowButtons={true}
              shoudShowRegion={true}
              NavigationLink="system-configuration"
              heading="Business Brands"
              subheading="Configure business brands here."
            />
          }
        />
        <Route
          path="/system-configuration/:brandId/:archetypeId"
          element={
            <TabsConfiguration
              type="system"
              items={tabItems[TabItemTypes.SYSTEM]}
            />
          }
        />
        <Route path="/master-data" element={<MasterData />} />
        <Route
          path="/process-configuration/:brandId/:archetypeId"
          element={
            <TabsConfiguration
              type="process"
              items={tabItems[TabItemTypes.PROCESS]}
            />
          }
        />
        <Route path="/user-management" element={<Users />} />
        <Route path="/customers" element={<Customers />} />
      </Routes>
    </Suspense>
  );
}
