import { Brand, Region } from "../../models/Brand";
import { doDelete, doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getBrands = (): Promise<any> =>
  doGet(`${getApiUrl()}/businessbrand/get`);

export const getBrandById = (id: string): Promise<Brand> =>
  doGet(`${getApiUrl()}/businessbrand/getbyid/${id}`);

export const createOrUpdateBrand = (brand: Brand): Promise<any> => {
  let apiUrl = getApiUrl();
  return brand.id === 0
    ? doPost(`${apiUrl}/businessbrand/create`, brand)
    : doPut(`${apiUrl}/businessbrand/update`, brand);
};

export const createOrUpdateRegion = (region: Region): Promise<any> => {
  let apiUrl = getApiUrl();
  return region.regionId === 0
    ? doPost(`${apiUrl}/region/create`, region)
    : doPut(`${apiUrl}/region/update`, region);
};

export const getRegionsByBrandId = (brandId: string): Promise<Region[]> => {
  return doGet(`${getApiUrl()}/region/getbrandregion/${brandId}`);
};

export const deleteBusinessEntity = (id: number, type: string): Promise<any> =>
  doDelete(`${getApiUrl()}/${type}/delete/${id}`);
