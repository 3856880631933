import styled from "styled-components";
import { McButton } from "@maersk-global/mds-react-wrapper/components-core/mc-button";

export default function Welcome({
  heading,
  subHeading,
  buttonLabel,
  onButtonClick,
  icon,
  isButtonLoadingEnabled,
  isDisabled = false,
}: Readonly<{
  heading: string;
  subHeading: string;
  buttonLabel: string;
  icon?: string;
  onButtonClick: () => void;
  isButtonLoadingEnabled?: boolean;
  isDisabled?: boolean;
}>) {
  return (
    <ContentWrapper>
      <div className="content">
        <div className="headingContainer">
          <h1 className="headingWrapper">{heading}</h1>
          <div className="subHeadingWrapper">{subHeading}</div>
        </div>
        <div className="btn-Wrapper">
          <McButton
            fit={"large"}
            id="welcome-action-button"
            label={buttonLabel}
            appearance={"primary"}
            name="add-new-brand"
            onClick={onButtonClick}
            type="button"
            variant={"filled"}
            className="btn-add-item"
            icon={icon ?? undefined}
            loading={isButtonLoadingEnabled || false}
            disabled={isDisabled}
          />
        </div>
      </div>
    </ContentWrapper>
  );
}

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;

  .content {
    flex-shrink: 0;
    border: 1px dashed
      var(--mds_brand_appearance_primary_default_border-color);
    padding: 50px;
    width: 700px;
    height: 330px;
  }

  .headingWrapper {
    font-style: normal;
    color: var(--mds_brand_appearance_primary_default_text-color);
  }

  .subHeadingWrapper {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: var(--mds_brand_appearance_primary_default_text-color);    
  }

  .btn-Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .btn-add-item::part(button) {
    min-width: 253px;
    height: 70px;
    padding: 22px;
  }
`;
