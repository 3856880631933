import styled from "styled-components";

/**
 * Renders a clickable text component that triggers a callback function when clicked.
 *
 * This component is designed to display additional text that acts as a link or button,
 * typically used for secondary actions or information in a UI. The text is styled with
 * an underline, bold font weight, and uses a specific color from the application's color
 * scheme. When the text is clicked, a callback function is executed, which can be used
 * to perform various actions such as navigation, displaying a modal, or any other custom
 * behavior. The component requires the text to display, a unique identifier, and the
 * callback function as props.
 *
 * @param {Object} props - The component props.
 * @param {string} props.additionalText - The text to be displayed as the clickable link.
 * @param {string} props.id - A unique identifier for the clickable text, passed back in the callback.
 * @param {Function} props.onAdditionalTextClick - The callback function to execute when the text is clicked. Receives the `id` as an argument.
 * @returns {JSX.Element} The rendered clickable text component.
 */
export default function AdditionalLinkText({
  additionalText,
  id,
  onAdditionalTextClick,
}: Readonly<{
  additionalText: string;
  id: string;
  onAdditionalTextClick: (id: string) => void;
}>): JSX.Element {
  return (
    <AdditionalTextContent onClick={() => onAdditionalTextClick(id)}>
      {additionalText}
    </AdditionalTextContent>
  );
}

export const AdditionalTextContent = styled.span`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: var(--mds_brand_appearance_secondary_default_border-color);
`;
