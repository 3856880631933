import React, { createContext, useEffect, useMemo, useState } from "react";
import { themes, themeThumbnailLogo } from "../../constants/Themes";
import { toastError } from "../common/toast";

interface ThemeContextProps {
  theme: string;
  setTheme: (theme: string) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: themes.MAERSK,
  setTheme: () => {}
});

let previousLinkElement: HTMLLinkElement | null = null;

const loadDesignTokens = async (theme: string) => {
  try {
    // Remove the previously loaded CSS
    if (previousLinkElement && document.head.contains(previousLinkElement)) {
      document.head.removeChild(previousLinkElement);
    }

    let cssPath = "";
    switch (theme) {
      case themes.MAERSK:
        cssPath =
          "https://assets.maerskline.com/mds/latest/design-tokens/maersk/light/css/design-tokens-px.min.css";
        break;
      case themes.APMTERMINALS:
        cssPath =
          "https://assets.maerskline.com/mds/latest/design-tokens/apmterminals/light/css/design-tokens-px.min.css";
        break;
      default:
        break;
    }

    if (cssPath) {
      // Create a new link element for the CSS
      const linkElement = document.createElement("link");
      linkElement.rel = "stylesheet";
      linkElement.href = cssPath;

      // Append the new link element to the document head
      document.head.appendChild(linkElement);

      // Update the previous link element
      previousLinkElement = linkElement;
    }
  } catch (error) {
    toastError("Error loading design tokens");
  }
};

export default function ThemeImporter({
  userTheme,
  children,
}: Readonly<{
  userTheme: string;
  children: React.ReactNode;
}>) {
  const [theme, setTheme] = useState<string>(userTheme);

  useEffect(() => {
    loadDesignTokens(theme);
  }, [theme]);

  const contextValue = useMemo(
    () => ({ theme, setTheme }),
    [theme]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <div>{children}</div>
    </ThemeContext.Provider>
  );
}
