import { McNotification } from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";

/**
 * `Notifications` is a React functional component that renders a notification message or a list of messages using the `McNotification` component from the `@maersk-global/mds-react-wrapper` package. The notification's appearance is determined by the `variant` prop, which can be one of several predefined types.
 *
 * Props:
 * - `description`: string[] - An array of strings where each string represents a notification message. If there is only one message, it will be displayed directly. If there are multiple messages, they will be displayed as a list.
 * - `variant`: "info" | "success" | "warning" | "error" | "neutral-weak" - Determines the appearance of the notification based on the type of message (e.g., success, error). This affects the color and icon used in the notification.
 *
 * The component conditionally renders its content based on the number of messages in the `description` array:
 * - If there is only one message, it displays that message directly within the `McNotification` component.
 * - If there are multiple messages, it maps over the `description` array to create a list of `<li>` elements, which are then wrapped in a `<ul>` and displayed within the `McNotification`.
 *
 * The `NotificationsWrapper` styled component is used to wrap the `McNotification` component, applying a bottom margin of 5px for spacing.
 *
 * Usage Example:
 * ```tsx
 * <Notifications
 *   description={["Your action was successful.", "Please wait for processing."]}
 *   variant="success"
 * />
 * ```
 *
 * Note: This component is designed to be flexible, allowing for both single and multiple notification messages to be displayed in a user-friendly manner.
 */
export default function Notifications({
  description,
  variant,
}: Readonly<{
  description: string[];
  variant: "info" | "success" | "warning" | "error" | "neutral-weak";
}>) {
  let list = null;

  if (description) {
    if (description.length === 1) {
      list = <>{description[0]}</>;
    } else if (description.length > 1) {
      const listItems = description.map((desc, index) => (
        <li key={`item-${desc}`}>{desc}</li>
      ));

      list = <ul style={{ listStyle: "inside" }}>{listItems}</ul>;
    }
  }
  return (
    <NotificationsWrapper>
      <McNotification appearance={variant} fit="small">
        {list}
      </McNotification>
    </NotificationsWrapper>
  );
}

const NotificationsWrapper = styled.div`
  margin-bottom: 5px;
`;
