import { useEffect, useReducer } from "react";
import CommonModal, {
  AddNewContentText,
  ExpandableContentWrapper,
} from "../../../components/common/commonModal";
import MultiSelect from "@maersk-global/community-react-multi-select";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../reducers/error";
import {
  defaultRegion,
  regionActions,
  regionReducer,
} from "../reducers/region";
import { createOrUpdateRegion } from "../../../services/Brands";
import { toast } from "../../../components/common/toast";
import { Area, Country, Region } from "../../../models/Brand";
import { DeleteIcon, StaticIcon } from "../../../components/common/icons";
import Tooltip from "../../../components/common/tooltip";
import { colors } from "../../../styles/colors";
import { McInput } from "@maersk-global/mds-react-wrapper";
import Box from "../../../components/common/box";
import Info from "../../../components/common/info";
import { BrandsInfo } from "../../../constants/Info";

const errorMessagesValidation = {
  REGION_NAME_IS_REQUIRED: "Region name is required.",
  AREA_NAME_IS_REQUIRED: "Area name is required.",
  COUNTRY_IS_REQUIRED:
    "At least one country must be selected while selecting a region.",
  AREA_COUNTRY_IS_REQUIRED:
    "At least one country must be selected while selecting an area.",
};

export default function AddEditRegionModal(props: any) {
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const [region, dispatch] = useReducer(regionReducer, defaultRegion);
  const countries: Country[] = props.masterCountries;

  useEffect(() => {
    dispatch({
      type: regionActions.SET_REGION,
      mastercountries: countries,
      editableRegion: {
        ...props.editableRegion,
      },
    });
  }, [props.isOpen, props.editableRegion]);

  const promiseOptions = (inputValue: string) => {
    return new Promise<Country[]>((resolve) => {
      setTimeout(() => {
        resolve(filterCountry(inputValue));
      }, 1000);
    });
  };

  const handleRegionCountryChange = (newValue: Country[] | [] | null) => {
    dispatch({
      type: regionActions.REGION_COUNTRY_CHANGE,
      countries: newValue,
    });
  };

  const handleAreaCountryChange = (
    newValue: Country[] | [] | null,
    action: any,
    index: number
  ) => {
    dispatch({
      type: regionActions.AREA_COUNTRY_CHANGE,
      countries: newValue,
      index: index,
    });
  };

  const handleRegionNameChange = (e: any) => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    dispatch({
      type: regionActions.REGION_NAME_CHANGE,
      regionName: e.target.value,
    });
  };

  const handleAreaNameChange = (e: any, index: number) => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    dispatch({
      type: regionActions.AREA_NAME_CHANGE,
      areaName: e.target.value,
      index: index,
    });
  };

  const filterCountry = (inputValue: string) => {
    return countries.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const addNewArea = () => {
    dispatch({ type: regionActions.ADD_AREA });
  };

  const onModalClose = () => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    props.onClose();
  };

  const addOrUpdateRegion = async () => {
    let areas =
      region.areas.length > 0
        ? region.areas.map((area: Area) => ({
            areaId: area.areaId,
            areaName: area.areaName?.trim(),
            countries: area.selectedCountries.map(
              (country: { id: string; label: string; value: string }) =>
                country.id
            ),
          }))
        : [];

    let regionCountries =
      region?.countries?.length > 0
        ? region.countries.map((country: Country) => country.id)
        : [];

    let regionData: Region = {
      regionId: region.regionId,
      businessBrandId: region.businessBrandId,
      regionName: region.regionName?.trim(),
      countries: regionCountries,
      areas: areas,
    };

    if (!validateRegion(regionData)) {
      return;
    }
    createOrUpdateRegion(regionData)
      .then((response) => {
        toast(
          "Success",
          `Region 
      ${regionData.regionId === 0 ? "added" : "updated"} successfully`,
          "success"
        );
        props.onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const validateRegion = (region: Region) => {
    const errorMessages: string[] = [];
    if (
      region.regionName === "" &&
      !errorMessages.includes(errorMessagesValidation.REGION_NAME_IS_REQUIRED)
    ) {
      errorMessages.push("Region name is required.");
    }
    if (
      region.countries.length === 0 &&
      !errorMessages.includes(errorMessagesValidation.COUNTRY_IS_REQUIRED)
    )
      errorMessages.push(errorMessagesValidation.COUNTRY_IS_REQUIRED);

    region.areas.forEach((area: Area, index: number) => {
      if (
        area.areaName === "" &&
        !errorMessages.includes(errorMessagesValidation.AREA_NAME_IS_REQUIRED)
      ) {
        errorMessages.push(errorMessagesValidation.AREA_NAME_IS_REQUIRED);
      }
      if (
        area.countries.length === 0 &&
        !errorMessages.includes(
          errorMessagesValidation.AREA_COUNTRY_IS_REQUIRED
        )
      ) {
        errorMessages.push(errorMessagesValidation.AREA_COUNTRY_IS_REQUIRED);
      }
    });

    if (errorMessages.length > 0) {
      dispatchErrors({
        type: errorActions.ADD_ERROR,
        errorMessage: errorMessages,
      });
      return false;
    }
    return true;
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={() => addOrUpdateRegion()}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={props.isOpen}
      onModalClose={() => onModalClose()}
      heading={`${
        props.editableRegion.regionId === 0 ? "Add new" : "Edit"
      } region`}
      dimension="medium"
      errors={errors}
      height={"600px"}
    >
      <McInput
        fit="medium"
        id="regionName"
        label="Region Name"
        input={(e: any) => handleRegionNameChange(e)}
        placeholder=""
        required
        type="text"
        value={region.regionName}
        variant="default"
        maxlength={50}
      >
        <Info
          tooltipText="Region Name"
          popoverContent={BrandsInfo.AddEditRegion_RegionName}
        />
      </McInput>

      <MultiSelect
        className="multi-select"
        closeMenuOnScroll
        fit="medium"
        id="RegionCountryMultiselect_id"
        isAsync
        isClearable
        label="Brand Country"
        name="Brand country multiselect"
        onChange={handleRegionCountryChange}
        options={countries}
        defaultOptions={countries}
        value={region.countries}
        loadOptions={promiseOptions}
        orientation="vertical"
        renderValue="label"
        variant="default"
        placeholder="Select one or more countries from the list.."
        tooltipDescription={BrandsInfo.AddEditRegion_RegionCountry}
      />
      {props.brandId}
      {region.areas.map((area: Area, index: number) => {
        return (
          <ExpandableContentWrapper key={area.areaId}>
            {area.areaId === 0 && (
              <Box
                display="flex"
                gridTemplateColumns="1fr"
                gridGap={3}
                style={{ justifyContent: "flex-end" }}
              >
                <Tooltip content="Remove Area" position="left-top">
                  <DeleteIcon
                    onClick={() =>
                      dispatch({
                        type: regionActions.REMOVE_AREA,
                        index: index,
                      })
                    }
                    slot="trigger"
                  />
                </Tooltip>
              </Box>
            )}

            <Box display="grid" gridTemplateColumns="20fr 1fr" gridGap={3}>
              <McInput
                fit="medium"
                id={index.toString()}
                label="Area"
                input={(e: any) => handleAreaNameChange(e, index)}
                placeholder=""
                required
                type="text"
                value={area.areaName}
                variant="default"
                maxlength={50}
              >
                <Info
                  tooltipText="Area"
                  popoverContent={BrandsInfo.AddEditRegion_AreaName}
                />
              </McInput>
            </Box>
            <Box display="grid" gridTemplateColumns="20fr 1fr" gridGap={3}>
              <Box p={3}>
                <MultiSelect
                  className="multi-select"
                  closeMenuOnScroll
                  fit="medium"
                  id="MultipleSelectDefault_id"
                  isClearable
                  label="Country"
                  name="MultipleSelectDefault"
                  onChange={(selectedOptions, actions) =>
                    handleAreaCountryChange(selectedOptions, actions, index)
                  }
                  options={area.countries}
                  orientation="vertical"
                  placeholder="Select countries from the list"
                  renderValue="label"
                  value={area.selectedCountries}
                  variant="checkbox"
                  tooltipDescription={BrandsInfo.AddEditRegion_AreaCountry}
                />
              </Box>
            </Box>
          </ExpandableContentWrapper>
        );
      })}
      <AddNewContentText onClick={() => addNewArea()}>
        <StaticIcon
          icon="plus"
          color={`${colors["mds_core_button_primary_filled_background-color"]}`}
        />
        <span>Add new area</span>
      </AddNewContentText>
    </CommonModal>
  );
}
