import { doGet, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getUserTheme = (): Promise<string> => {
  return doGet(`${getApiUrl()}/theme/get`);
};

export const setUserTheme = (theme: string): Promise<any> => {
  return doPut(`${getApiUrl()}/theme/save`, { theme: theme });
};
