import { McLoadingIndicator } from "@maersk-global/mds-react-wrapper";

/**
 * Loading Indicator Component
 * 
 * This component utilizes the `McLoadingIndicator` from the `@maersk-global/mds-react-wrapper` package to display
 * a loading indicator. The loading indicator is designed to provide visual feedback during operations that require
 * waiting time, such as data fetching or processing tasks.
 * 
 * The `McLoadingIndicator` is configured with specific properties to customize its appearance:
 * - `label`: An empty string is provided, indicating that no label text is displayed alongside the loading indicator.
 * - `fit`: Set to "medium", determining the size of the loading indicator.
 * - `variant`: Configured as "bar", specifying the style of the loading indicator to be a bar.
 * 
 * Usage:
 * This loading indicator can be used in various parts of the application where there is a need to inform the user
 * that a background operation is in progress. It can be rendered directly or included as part of larger components
 * or pages that require a loading state.
 */
export const loadingIndicator = (
  <McLoadingIndicator label="" fit="large" />
);
