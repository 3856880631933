export const getApplicationConfig = () => (window as any).config;

export const getApiUrl = () => {
  let appConfig = getApplicationConfig();
  return `${appConfig.apiResource.baseAddress}/api/${appConfig.apiResource.apiVersion}`;
};

export const getNotificationsApiUrl = () => {
  let appConfig = getApplicationConfig();
  return `${appConfig.notificationsApiResource.baseAddress}/api`;
};
