import { useContext, useState } from "react";
import ThemeSelection from "../../components/common/themeSelection";
import { ThemeContext } from "../../components/theme/themeImporter";
import { setUserTheme } from "../../services/Theme";
import { toastError } from "../../components/common/toast";
import SiteElementLayout from "../../components/common/siteElementLayout";
import { ThemesInfo } from "../../constants/SiteInfo";

export default function Themes() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [prevTheme, setPrevTheme] = useState<string>(theme);
  const onThemeSelected = (code: string) => {
    setTheme(code);

    setUserTheme(code)
      .then((response) => {
        setPrevTheme(code);
      })
      .catch((error) => {
        setTheme(prevTheme);
        toastError("Error setting theme");
      });
  };
  return (
    <SiteElementLayout
      heading="Theme"
      shouldShowComments={false}
      popoverContent={ThemesInfo.Section_Heading}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <ThemeSelection theme={theme} onThemeSelected={onThemeSelected} />
      </div>
    </SiteElementLayout>
  );
}
