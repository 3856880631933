import { useContext, useEffect, useRef, useState } from "react";
import { StaticIcon } from "../common/icons";
import { loadingIndicator } from "../common/loading";
import Comment from "./comment";
import CommentReply from "./commentReply";
import { addSingleComment, getComments } from "../../services/Comments";
import { toastError } from "../common/toast";
import { UserContext } from "../layout/layout";
import NoDataAvailable from "../common/noDataAvailable";
import { extractMentions } from "../../utils/utils";
import { McButton, McPopover } from "@maersk-global/mds-react-wrapper";
import "./styles/comments.scss";

export default function Comments({
  sectionId,
}: Readonly<{ sectionId: number }>) {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState<any[]>([]);
  const [hasComments, setHasComments] = useState<boolean>(false);
  const [addedComment, setAddedComment] = useState<string>("");
  const [commentIndex, setCommentIndex] = useState<number>(0);
  const { email } = useContext(UserContext);
  const commentsEndRef: any = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
      if (sectionId > 0) {
        getComments(sectionId)
          .then((response) => {
            setComments(response);
            setLoading(false);
          })
          .catch((error) => {
            toastError("Error", error.message);
          });
      }
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  useEffect(() => {
    if (sectionId > 0) {
      getComments(sectionId)
        .then((response) => {
          setComments(response);
          setHasComments(response.length > 0);
          setLoading(false);
        })
        .catch((error) => {
          toastError("Error", error.message);
        });
    }
  }, [sectionId]);

  useEffect(() => {
    if (commentsEndRef.current && comments.length > 0) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const addedCommentItem = comments.find((c) => c.id === commentIndex);
    if (addedCommentItem) {
      let taggedUsers = extractMentions(addedCommentItem.body);

      addSingleComment(sectionId, addedComment, email, taggedUsers)
        .then((response) => {
          setComments((prevComments) =>
            prevComments.map((c) =>
              c.id === commentIndex ? { ...c, id: response } : c
            )
          );
        })
        .catch((error) => {
          toastError("Error", error.message);
        });
    }
  }, [comments]);

  const onAddComment = (comment: string, id: number) => {
    let commentId = comments.length + 1;
    setComments([
      ...comments,
      {
        id: commentId,
        body: comment,
        userName: email,
        updatedAt: new Date().toISOString(),
        replies: [],
        isEdited: false,
        isDeleted: false,
      },
    ]);

    setCommentIndex(commentId);
    setAddedComment(comment);
    setHasComments(true);
  };

  return (
    <McPopover
      trigger="click"
      width="700px"
      preventcloseonblur={true}
      position="bottom-left"
      hide={() => setIsOpen(false)}
      show={() => setIsOpen(true)}
    >
      <McButton
        fit="small"
        variant="filled"
        appearance={hasComments ? "primary" : "neutral"}
        slot="trigger"
      >
        <span slot="icon">
          <StaticIcon
            icon="comment-plus"
            color={
              hasComments
                ? "var(--mds_brand_appearance_neutral_default_background-color)"
                : ""
            }
          />
        </span>
      </McButton>
      <div className="popover-content">
        <div className="header-wrapper">
          <h3>Comments</h3>
        </div>
        {loading ? (
          loadingIndicator
        ) : (
          <>
            {comments.length === 0 ? (
              <div className="no-comments-wrapper">
                <NoDataAvailable text="No comments added..." />
              </div>
            ) : (
              <>
                <div className="comments-wrapper">
                  {comments.map((comment) => (
                    <Comment
                      key={comment.id}
                      commentText={comment.body}
                      id={comment.id}
                      updatedBy={comment.userName}
                      date={comment.updatedAt}
                      replies={comment.replies}
                      isEdited={comment.isEdited}
                      isDeleted={comment.isDeleted ?? false}
                      sectionId={sectionId}
                    />
                  ))}
                </div>
                <div ref={commentsEndRef} />
              </>
            )}
            <div className="comment-reply-wrapper">
              <CommentReply onSend={onAddComment} id={0} />
            </div>
          </>
        )}
      </div>
    </McPopover>
  );
}
