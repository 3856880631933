import { TaggableUsersContext } from "./contexts/taggableUsersContext";
import Comments from "./comments";

export default function CommentsWrapper({
  taggableUsers,
  sectionId,
}: {
  taggableUsers: any[];
  sectionId: number;
}) {
  return (
    <TaggableUsersContext.Provider value={{ taggableUsers: taggableUsers }}>
      <Comments sectionId={sectionId} />
    </TaggableUsersContext.Provider>
  );
}
