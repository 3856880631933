import { ItemType } from "@maersk-global/community-react-tabs";
import SiteTabsTitle from "../components/common/siteTabStatusIndicator";

export const TabItemTypes = {
  PROCESS: "process",
  SYSTEM: "system",
  SITE: "site",
};

export const tabItemCodes = {
  site: "site",
  siteConfiguration: "site-configuration",
  impact: "impact",
  dependencies: "dependencies",
  contacts: "contacts",
  locationAndCustomers: "location-and-customers",
  continuityPlan: "continuity-plan",
  processes: "processes",
  processDependencies: "process-dependencies",
  siteDetails: "site-details",
  operationalParameters: "site-operational-parameters",
  keyCustomers: "site-key-customers",
  internalExternalContacts: "site-internal-external-contacts",
  alternativeLocation: "site-alternative-location",
};

export const tabItemTitles = {
  siteDetails: "Site Details",
  operationalParameters: "Operational Parameters",
  keyCustomers: "Customers",
  impact: "Impact",
  dependencies: "Dependency",
  continuityPlan: "Continuity Plan",
  alternativeLocation: "Alternative Location",
  internalExternalContacts: "Contacts",
};

export const tabItems: { [tabItemType: string]: Array<ItemType> } = {
  [TabItemTypes.SYSTEM]: [
    {
      id: tabItemCodes.site,
      active: true,
      title: "Site",
    },
    {
      id: tabItemCodes.siteConfiguration,
      active: false,
      title: "Site Configuration",
    },
    {
      id: tabItemCodes.impact,
      active: false,
      title: "Impact",
    },
    {
      id: tabItemCodes.dependencies,
      active: false,
      title: "Dependencies",
    },
    {
      id: tabItemCodes.contacts,
      active: false,
      title: "Contacts",
    },
  ],
  [TabItemTypes.PROCESS]: [
    {
      id: tabItemCodes.processes,
      active: true,
      title: "Impact",
    },
    {
      id: tabItemCodes.processDependencies,
      active: false,
      title: "Dependencies",
    },
    {
      id: tabItemCodes.continuityPlan,
      active: false,
      title: "Continuity Plan",
    },
  ],
  [TabItemTypes.SITE]: [
    {
      id: tabItemCodes.siteDetails,
      active: true,
      title: <SiteTabsTitle title={tabItemTitles.siteDetails} />,
    },
    {
      id: tabItemCodes.operationalParameters,
      active: false,
      title: <SiteTabsTitle title={tabItemTitles.operationalParameters} />,
    },
    {
      id: tabItemCodes.keyCustomers,
      active: false,
      title: <SiteTabsTitle title={tabItemTitles.keyCustomers} />,
    },
    {
      id: tabItemCodes.impact,
      active: false,
      title: <SiteTabsTitle title={tabItemTitles.impact} />,
    },
    {
      id: tabItemCodes.dependencies,
      active: false,
      title: <SiteTabsTitle title={tabItemTitles.dependencies} />,
    },
    {
      id: tabItemCodes.continuityPlan,
      active: false,
      title: <SiteTabsTitle title={tabItemTitles.continuityPlan} />,
    },
    {
      id: tabItemCodes.alternativeLocation,
      active: false,
      title: <SiteTabsTitle title={tabItemTitles.alternativeLocation} />,
    },
    {
      id: tabItemCodes.internalExternalContacts,
      active: false,
      title: <SiteTabsTitle title={tabItemTitles.internalExternalContacts} />,
    },
  ],
};
