import { createContext, useEffect, useMemo, useState } from "react";
import AppHeader from "./appHeader";
import { UserMappedSites } from "../../models/SiteDashboard";
import { getSiteUserDashboard } from "../../services/SiteDashboard/Index";
import { toastError } from "../common/toast";
import { getSiteNavItems } from "../../utils";
import { SidebarItemsProps } from "@maersk-global/community-react";
import SidebarLayout from "./sidebar/SidebarLayout";
import ThemeImporter from "../theme/themeImporter";
import { getUserTheme } from "../../services/Theme";
import { themes } from "../../constants/Themes";
import { loadingIndicator } from "../common/loading";
import { AppContainer } from "./layout.styles";

export const SitesContext = createContext<{
  userMappedSites: UserMappedSites[];
}>({
  userMappedSites: [],
});

export default function SiteLayout({
  userName,
  role,
}: Readonly<{ userName: string; role: string }>) {
  const [userMappedSites, setUserMappedSites] = useState<UserMappedSites[]>([]);
  const [navItems, setNavItems] = useState<SidebarItemsProps[]>([]);
  const [theme, setTheme] = useState<string>(themes.MAERSK);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getSiteUserDashboard()
      .then((data) => {
        setUserMappedSites(data);
      })
      .catch((error) => {
        toastError(error.message);
        return [];
      });
  }, []);

  useEffect(() => {
    getUserTheme().then((response) => {
      setTheme(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let siteNavItems = getSiteNavItems(userMappedSites);
    setNavItems(siteNavItems);
  }, [userMappedSites]);

  const updateSite = (site: UserMappedSites) => {
    let index = userMappedSites.findIndex((item) => item.id === site.id);
    let updatedSites = [...userMappedSites];
    updatedSites[index] = site;

    setUserMappedSites(updatedSites);
  };

  const contextValue = useMemo(() => {
    return { userMappedSites, updateSite };
  }, [userMappedSites]);

  if (loading) return loadingIndicator;

  return (
    <ThemeImporter userTheme={theme}>
      <AppContainer>
        <AppHeader
          userName={userName}
          role={role}
          shouldShowNotification={true}
          shouldShowSettings={true}
        />
        {userMappedSites.length > 0 && (
          <SitesContext.Provider value={contextValue}>
            <SidebarLayout navItems={navItems} isExpanded={false} />
          </SitesContext.Provider>
        )}
      </AppContainer>
    </ThemeImporter>
  );
}
