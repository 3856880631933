import { Route, Routes } from "react-router-dom";
import { TabItemTypes, tabItems } from "../constants/TabItems";
import { Suspense, lazy } from "react";
import { loadingIndicator } from "../components/common/loading";

const MasterData = lazy(() => import("../pages/Brands/MasterData"));
const TabsConfiguration = lazy(
  () => import("../pages/Configuration/TabsConfiguration")
);

export default function ProcessOwnerRoutes() {
  return (
    <Suspense fallback={loadingIndicator}>
      <Routes>
        <Route path="/" element={<MasterData />} />
        <Route path="/master-data" element={<MasterData />} />
        <Route
          path="/process-configuration/:brandId/:archetypeId"
          element={
            <TabsConfiguration
              type="process"
              items={tabItems[TabItemTypes.PROCESS]}
            />
          }
        />
      </Routes>
    </Suspense>
  );
}
