import CommentsWrapper from "../../components/comments/commentsWrapper";
import useSite from "../../hooks/useSite";

export default function SiteComments({ sectionId }: { sectionId: number }) {
  const { siteId, site } = useSite();

  return (
    <CommentsWrapper taggableUsers={site.siteUsers} sectionId={sectionId} />
  );
}
