import { createContext, useEffect, useMemo, useState } from "react";
import {
  Roles,
  buildConcreteRolesFromTokenRoles,
  buildRolesFromTokenRoles,
  getUserInfo,
} from "../../utils";
import { toast } from "../common/toast";
import SiteLayout from "./siteLayout";
import GscAdminLayout from "./gscAdminLayout";

export interface UserContextProps {
  email: string;
  roles: Array<string>;
}
export const UserContext = createContext<UserContextProps>({
  email: "",
  roles: [],
});

export default function Layout() {
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [roles, setRoles] = useState<Array<string>>([]);
  const [isValidUser, setIsValidUser] = useState<boolean>(true);

  const [isSiteRole, setIsSiteRole] = useState<boolean>(false);
  const [concreteRole, setConcreteRole] = useState<string>("");

  useEffect(() => {
    let userInfo = getUserInfo();

    if (userInfo && userInfo.userName && userInfo.roles) {
      setUserName(userInfo.userName);
      setEmail(userInfo.email);
      let tokenRoles = buildRolesFromTokenRoles(userInfo.roles);
      setRoles(tokenRoles);

      let concreteRole = buildConcreteRolesFromTokenRoles(tokenRoles);
      setConcreteRole(concreteRole);

      if (tokenRoles.length === 0) setIsValidUser(false);
    } else {
      setIsValidUser(false);
    }
  }, []);

  useEffect(() => {
    let isSiteRole =
      roles.includes(Roles.SITECOORDINATOR) ||
      roles.includes(Roles.SITEOWNER) ||
      roles.includes(Roles.SITEUSER) ||
      roles.includes(Roles.BRADMIN);

    setIsSiteRole(isSiteRole);
  }, [roles]);

  useEffect(() => {
    if (!isValidUser) {
      toast(
        "Unauthorized",
        "You do not have enough permission to access this page. Please contact your administrator.",
        "error"
      );
    }
  }, [isValidUser]);

  const userContextValue = useMemo(
    () => ({ email: email, roles: roles }),
    [email, roles]
  );

  return (
    <>
      {isValidUser && (
        <UserContext.Provider value={userContextValue}>
          
            {isSiteRole ? (
              <SiteLayout userName={userName} role={concreteRole} />
            ) : (
              <GscAdminLayout userName={userName} role={concreteRole} />
            )}
         
        </UserContext.Provider>
      )}
    </>
  );
}
