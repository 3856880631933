import { useContext, useEffect, useState } from "react";
import { UserMappedSites } from "../models/SiteDashboard";
import { SiteContext } from "../pages/Site/SiteGuard";

const useSite = () => {
  const { site, updateSite } = useContext(SiteContext) as {
    site: UserMappedSites;
    updateSite: (site: UserMappedSites) => void;
  };
  const [siteId, setSiteId] = useState<number>(0);

  useEffect(() => {
    setSiteId(site.id);
  }, [site.id]);

  const updateSingleSite = (site: UserMappedSites) => {
    updateSite(site);
  };

  return { siteId, site, updateSingleSite };
};

export default useSite;
