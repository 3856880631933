import { useEffect, useState } from "react";
import { McButton } from "@maersk-global/mds-react-wrapper";
import "./styles/comments.scss";
import TagInput from "./tagInput";

export default function CommentReply({
  onSend,
  id,
}: Readonly<{ onSend: (text: string, id: number) => void; id: number }>) {
  const [replyComments, setReplyComments] = useState<string>("");
  const [parentId, setParentId] = useState<number>(0);

  useEffect(() => {
    setParentId(id);
  }, [id]);

  const onReply = () => {
    if (replyComments.trim() === "") return;
    onSend(replyComments, parentId);
    setReplyComments("");
  };

  return (
    <div className="comments-reply-wrapper">
      <div className="reply-textarea">
        <TagInput value={replyComments} onTextChange={setReplyComments} />
      </div>
      <div>
        <McButton
          fit="small"
          appearance="neutral"
          variant="outlined"
          label="Send"
          click={onReply}
        />
      </div>
    </div>
  );
}
