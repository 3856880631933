import { useState } from "react";
import styled from "styled-components";
import { McButton, McSwitch } from "@maersk-global/mds-react-wrapper";
import { StaticIcon } from "./icons";
import Box from "./box";
import AdditionalLinkText from "./additionalLinkText";

/**
 * `Accordian` is a React functional component that renders an accordion-style collapsible container.
 *  It is designed to display a header, optional action buttons (Create, Edit, Delete), and collapsible content. 
 * The accordion can be expanded or collapsed to show or hide its children content. 
 * It supports additional functionalities like displaying an extra text link and customizing button texts and icons.
 * Props:
 * - `headerText`: string - The text displayed in the accordion's header.
 * - `shouldShowCreateButton`: boolean - Determines if the Create button should be displayed.
 * - `shouldShowEditButton`: boolean - Determines if the Edit button should be displayed.
 * - `shouldShowDeleteButton`: boolean - Determines if the Delete button should be displayed.
 * - `onDelete`: function - Callback function that is called when the Delete button is clicked.
 * - `id`: string | number - A unique identifier for the accordion item.
 * - `keyName`: string - A key name associated with the accordion item.
 * - `keyValue`: string - The value associated with the `keyName`.
 * - `path`: string - A path or URL associated with the accordion item.
 * - `onEdit`: function - Callback function that is called when the Edit button is clicked.
 * - `onCreate`: function - Callback function that is called when the Create button is clicked.
 * - `addButtonText`: string - Custom text for the Add/Create button.
 * - `children`: React.ReactNode - The content to be displayed inside the accordion when it is expanded.
 * - `info`: any - Additional information that can be used within the accordion or its callbacks.
 * - `editButtonIcon`: React.ReactNode - Custom icon for the Edit button.
 * - `editButtonText`: string - Custom text for the Edit button.
 * - `shouldShowAdditionalText`: boolean - Determines if additional text link should be displayed.
 * - `additionalText`: string - The text for the additional link.
 * - `onAdditionalTextClick`: function - Callback function that is called when the additional text link is clicked.

 * The component uses a `useState` hook to manage its expanded/collapsed state. The `handleAccordianExpansion` function toggles this state, which controls the visibility of the `children` content.

 * The component structure includes:
 * - An `AccordianContainer` styled component that wraps the entire accordion. It applies a conditional border style based on the expanded state.
 * - A `ContentWrapper` styled component that contains the header and optional buttons.
 * - A `Box` component that wraps the header text and optional action buttons.
 * - Conditional rendering of Create, Edit, and Delete buttons based on the respective props.
 * - Optional rendering of an `AdditionalLinkText` component based on `shouldShowAdditionalText`.

 * Usage Example:
 * ```tsx
 * <Accordian
 *   headerText="Accordion Title"
 *   shouldShowCreateButton={true}
 *   onCreate={() => console.log("Create action")}
 *   addButtonText="Add Item"
 * >
 *   <p>Content to be shown when the accordion is expanded</p>
 * </Accordian>
 * ```

 * Note: This component is designed to be reusable and flexible, allowing for various configurations and content types within the accordion.
 */
export default function Accordian({
  headerText,
  shouldShowCreateButton,
  shouldShowEditButton,
  shouldShowDeleteButton,
  onDelete,
  id,
  keyName,
  keyValue,
  path,
  onEdit,
  onCreate,
  addButtonText,
  children,
  info,
  editButtonIcon,
  editButtonText,
  shouldShowAdditionalText = false,
  additionalText = "",
  onAdditionalTextClick,
  shouldShowSwitchButton = false,
  onSwitchButtonClick,
  shouldDisableAccordionOnSwitch = false,
  isSwitchInReadMode = false,
  isSwitchButtonDisabled = false,
  displayWarning = false,
}: any) {
  const [expanded, setExpanded] = useState(false);
  const [isSwitchButtonEnabled, setIsSwitchButtonEnabled] = useState(
    !isSwitchButtonDisabled
  );
  const [disabled, setDisabled] = useState(isSwitchButtonDisabled);
  const handleAccordianExpansion = () => {
    setExpanded(!expanded);
  };
  return (
    <AccordianContainer
      className={disabled ? "disabled" : expanded ? "withBorder" : ""}
    >
      <ContentWrapper>
        <Box display="flex">
          <Box className="accordianHeader" onClick={handleAccordianExpansion}>
            <div
              className={`mds-text--large-normal ${
                expanded ? "expanded-font" : ""
              }`}
            >
              {headerText}
            </div>
            <StaticIcon icon={expanded ? "chevron-up" : "chevron-down"} />
          </Box>
          {shouldShowSwitchButton && (
            <Box>
              <McSwitch
                change={(e: CustomEvent) => {
                  if (shouldDisableAccordionOnSwitch) {
                    setExpanded(false);
                    setDisabled(!disabled);
                  }
                  setIsSwitchButtonEnabled(!isSwitchButtonEnabled);
                  onSwitchButtonClick(id, e.detail);
                }}
                checked={isSwitchButtonEnabled}
                label=""
                disabled={isSwitchInReadMode}
              />
            </Box>
          )}
        </Box>
        <Box>
          <div className="actionButtonsWrapper">
            {shouldShowAdditionalText && (
              <AdditionalLinkText
                additionalText={additionalText}
                id={id}
                onAdditionalTextClick={onAdditionalTextClick}
              />
            )}
            {shouldShowDeleteButton && (
              <McButton
                data-testid="delete-accordian"
                appearance="neutral"
                fit="small"
                id="delete"
                justifyitems="center"
                label="Delete"
                name="delete"
                click={() => onDelete(id, keyName, keyValue, path, info)}
                type="button"
                variant="filled"
                className="btn-no-wrap"
                disabled={disabled}
              />
            )}
            {shouldShowEditButton && (
              <McButton
                appearance="neutral"
                fit="small"
                id="edit"
                justifyitems="center"
                icon={editButtonIcon ?? ""}
                label={editButtonText ?? "Edit"}
                name="primary"
                click={() => onEdit(id)}
                type="button"
                variant="filled"
                className="btn-no-wrap"
                disabled={disabled}
              />
            )}
            {shouldShowCreateButton && (
              <McButton
                appearance="secondary"
                id="create"
                justifyitems="center"
                label={addButtonText}
                name="create"
                click={() => onCreate(id)}
                variant="filled"
                fit="small"
                className="btn-no-wrap"
                disabled={disabled}
              />
            )}
            {displayWarning && (
              <StaticIcon icon="exclamation-triangle" size="20" />
            )}
          </div>
        </Box>
      </ContentWrapper>
      <HiddenContentWrapper isExpanded={expanded}>
        {!disabled && children}
      </HiddenContentWrapper>
    </AccordianContainer>
  );
}

export const AccordianContainer = styled(Box)`
  margin-bottom: 10px;

  &.disabled {
    background-color: var(--mds_brand_appearance_neutral_weak_border-color);
  }

  &.withBorder {
    border: 2px solid var(--mds_brand_appearance_secondary_default_border-color);
  }
`;

export const ContentWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid var(--mds_brand_appearance_neutral_weak_border-color);
  padding: 20px;
  align-items: center;

  .expanded-font {
    font-weight: bold;
  }

  .accordianHeader {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  .actionButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 10px;
  }
`;

export const HiddenContentWrapper = styled.section<{ isExpanded?: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? "" : "none")};
`;
