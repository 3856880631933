export const errorActions = {
    ADD_ERROR: "ADD_ERROR",
    REMOVE_ERROR: "REMOVE_ERROR"
}

export const defaultError = {
    errorMessage: [],
  };
  
export const errorReducer = (state: any, action: any) => {
    switch (action.type) {
      case errorActions.ADD_ERROR:
        return { ...state, errorMessage: action.errorMessage };
      case errorActions.REMOVE_ERROR:
        return { ...state, errorMessage: []};
      default:
        return state;
    }
  };