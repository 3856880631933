export const deleteActions = {
  SET_DELETE_MODAL_OPEN: "SET_DELETE_MODAL_OPEN",
  SET_DELETE_ENTITY: "SET_DELETE_ENTITY",
  SET_DELETE_MODAL_CLOSE: "SET_DELETE_MODAL_CLOSE",
};

export const defaultDeleteEntityState = {
  isModalOpen: false,
  deleteEntity: {
    id: 0,
    key: "",
    keyName: "",
    path: "",
    info: "",
  },
};

export const deleteReducer = (state: any, action: any) => {
  switch (action.type) {
    case deleteActions.SET_DELETE_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
      };
    case deleteActions.SET_DELETE_ENTITY:
      return {
        ...state,
        deleteEntity: {
          id: action.deleteEntity.id,
          key: action.deleteEntity.key,
          keyName: action.deleteEntity.keyName,
          path: action.deleteEntity.path,
          info: action.deleteEntity.info,
        },
      };
    case deleteActions.SET_DELETE_MODAL_CLOSE:
      return defaultDeleteEntityState;
    default:
      return state;
  }
};

export const onDeleteEntity = (
  dispatchDelete: Function,
  id: number,
  key: string,
  keyName: string,
  path: string,
  info: string
) => {
  dispatchDelete({
    type: deleteActions.SET_DELETE_ENTITY,
    deleteEntity: { key, id, keyName, path, info },
  });
  dispatchDelete({
    type: deleteActions.SET_DELETE_MODAL_OPEN,
  });
};
