import {
  TooltipAppearance,
  TooltipPosition,
} from "@maersk-global/mds-components-core/mc-tooltip/types";
import { McTooltip } from "@maersk-global/mds-react-wrapper";

/**
 * Displays a tooltip with customizable content, position, and appearance.
 *
 * This component wraps the `McTooltip` component from the `@maersk-global/mds-react-wrapper` package,
 * allowing for a simplified interface to display tooltips in the application. The tooltip can be
 * customized with different positions and appearances according to the design system's specifications.
 * It requires content to display within the tooltip and can wrap any React node to which the tooltip
 * should be attached.
 *
 * @param {Object} props - The component props.
 * @param {TooltipPosition} [props.position="top-left"] - The position of the tooltip relative to the child element. Defaults to "top-left".
 * @param {TooltipAppearance} [props.appearance="neutral-inverse"] - The visual style of the tooltip. Defaults to "neutral-inverse".
 * @param {string} props.content - The text or content to be displayed inside the tooltip.
 * @param {React.ReactNode} props.children - The child element(s) that the tooltip is attached to.
 * @returns {JSX.Element} The rendered tooltip component.
 */
export default function Tooltip({
  position,
  appearance,
  content,
  children,
}: Readonly<{
  position?: TooltipPosition;
  appearance?: TooltipAppearance;
  content: string;
  children: React.ReactNode;
}>): JSX.Element {
  return (
    <McTooltip
      position={position || "top-left"}
      appearance={appearance || "neutral-inverse"}
    >
      {children}
      <span>{content}</span>
    </McTooltip>
  );
}
