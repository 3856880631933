import { StaticIcon } from "./icons";

/**
 * `SiteTabsTitle` is a React functional component that renders a title for site tabs with an optional status indicator. The status indicator is represented by icons to denote validity or an error state. This component is designed to be used within a site's tabbed navigation or similar UI elements where a visual indication of the state of each tab is beneficial.

 * Props:
 * - `title`: string - The text to be displayed as the title of the tab.
 * - `isValid`: boolean | undefined - An optional boolean that, if provided, determines the status indicator to be displayed next to the title. If `true`, a check-circle icon is shown, indicating a valid state. If `false`, an exclamation-circle icon is shown, indicating an error or warning state. If `undefined`, no status indicator is displayed.

 * The component layout consists of:
 * - A parent `div` that uses flex display to align its children horizontally, with a gap of 5px between them.
 * - A `div` containing the `title`, styled with a font size of 15px.
 * - Another `div` that conditionally renders one of the following based on the `isValid` prop:
 *   - No icon if `isValid` is `undefined`.
 *   - A `StaticIcon` with a "check-circle" icon, size 24, and a green color if `isValid` is `true`.
 *   - A `StaticIcon` with an "exclamation-circle" icon, size 24, and a red color if `isValid` is `false`.

 * Usage Example:
 * ```tsx
 * <SiteTabsTitle title="Tab 1" isValid={true} />
 * <SiteTabsTitle title="Tab 2" isValid={false} />
 * <SiteTabsTitle title="Tab 3" />
 * ```

 * Note: This component relies on the `StaticIcon` component for rendering the status icons. The `StaticIcon` component should be defined elsewhere in the project and imported for use here.
 */
export default function SiteTabsTitle({
  title,
  isValid,
}: Readonly<{
  title: string;
  isValid?: boolean;
}>) {
  const iconElement = isValid ? (
    <StaticIcon icon="check-circle" size="24" color="var(--mds_brand_appearance_success_default_text-color)" />
  ) : (
    <StaticIcon icon="exclamation-circle" size="24" color="var(--mds_brand_appearance_error_default_text-color)" />
  );

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <div style={{ fontSize: "15px" }}>{title}</div>
      <div>{isValid === undefined ? <></> : iconElement}</div>
    </div>
  );
}