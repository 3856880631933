import { accessTokenStrategy, doGet, doPost, doPut } from "../../utils";
import { getNotificationsApiUrl } from "../../utils/application-utils";

export const getNotifications = (): Promise<any> =>
  doGet(
    `${getNotificationsApiUrl()}/notifications/alert`,
    accessTokenStrategy.NOTIFICATIONS
  );

export const readNotifications = (): Promise<any> =>
  doPut(
    `${getNotificationsApiUrl()}/notifications/alert/markasread`,
    null,
    accessTokenStrategy.NOTIFICATIONS
  );
