
export const mentionStyle = {
  control: {
    backgroundColor: "var(--mds_brand_appearance_neutral_default_background-color)",
    fontSize: 14,
    fontWeight: 400,
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: "10px 9px 9px 10px",
      border: "1px solid var(--mds_brand_appearance_neutral_weak_border-color)",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "var(--mds_brand_appearance_neutral_default_background-color)",
      border: "1px solid var(--mds_brand_appearance_neutral_default_border-color)",
      fontSize: 14,
      maxHeight: 200,
      overflow: "auto",
      position: "absolute",
      bottom: 14,
      minWidth: 200,
    },
    item: {
      padding: "0px 0 5px 15px",
      borderBottom: "1px solid var(--mds_brand_appearance_neutral_default_border-color)",
      
      "&focused": {
        backgroundColor: "var(--mds_brand_appearance_primary_weak_background-color)",
        color: "var(--mds_brand_appearance_primary_weak_on-background-color)"
      },
    },
  },
};
