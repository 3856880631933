import styled from "styled-components";

/**
 * NoDataAvailable Component
 *
 * A functional component designed to display a message when no data is available. It utilizes styled-components
 * for styling, ensuring the message is centered within a specified area. The component is flexible, allowing
 * for any text to be passed as a prop, making it reusable in various parts of an application where data might
 * be missing or unavailable.
 *
 * Props:
 * - `text`: A string that represents the message to be displayed when no data is available.
 *
 * Styling:
 * The `NoDataAvailableWrapper` styled component is used to style the container of the no data message. It ensures
 * that the message is displayed in the center of a 100px high area, with a border and text color defined by the
 * application's color scheme (sourced from `../../styles/colors`).
 *
 * Usage:
 * This component can be used in any part of the application where it's necessary to inform the user that no data
 * is available. It's particularly useful in list views, search results, or any other data-driven sections of the
 * application.
 */
export default function NoDataAvailable({ text }: Readonly<{ text: string }>) {
  return (
    <NoDataAvailableWrapper data-testid={text}>
      <b>{text}</b>
    </NoDataAvailableWrapper>
  );
}

const NoDataAvailableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid var(--mds_brand_appearance_neutral_weak_background-color);
`;
