import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { UserMappedSites } from "../../models/SiteDashboard";
import Notifications from "../../components/common/notifications";
import { loadingIndicator } from "../../components/common/loading";
import { SitesContext } from "../../components/layout/siteLayout";

export const SiteContext = createContext<{
  site: UserMappedSites;
}>({
  site: {} as UserMappedSites,
});

export default function SiteGuard({ children }: Readonly<{ children: any }>) {
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [isUserAssignedToSite, setIsUserAssignedToSite] = useState<
    boolean | null
  >(null);
  const [userMappedSite, setUserMappedSite] = useState<UserMappedSites>();

  const { userMappedSites, updateSite } = useContext(SitesContext) as {
    userMappedSites: UserMappedSites[];
    updateSite: (site: UserMappedSites) => void;
  };

  useEffect(() => {
    setLoading(true);

    if (userMappedSites.length === 0) {
      setLoading(false);
      return;
    }

    let numberedId = Number(id);
    let userMappedToSite = userMappedSites.find(
      (site) => site.id === numberedId
    );
    if (userMappedToSite) {
      setIsUserAssignedToSite(true);
      setUserMappedSite(userMappedToSite);
    } else {
      setIsUserAssignedToSite(false);
    }

    setLoading(false);
  }, [id, userMappedSite]);

  const contextValue = useMemo(() => {
    return { site: userMappedSite ?? ({} as UserMappedSites), updateSite };
  }, [userMappedSite]);

  if (loading) return loadingIndicator;

  if (isUserAssignedToSite !== null && !isUserAssignedToSite) {
    return (
      <Notifications
        description={[
          "You are currently not a member of the site. Please contact admin to be a part of this site.",
        ]}
        variant="error"
      />
    );
  }

  return isUserAssignedToSite ? (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  ) : (
    <></>
  );
}
