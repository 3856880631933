import { doDelete, doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getComments = (postId: number): Promise<any> =>
  doGet(`${getApiUrl()}/comment/${postId}`);

export const addSingleComment = (
  postId: number,
  comment: string,
  userName: string,
  taggedUsers: string[]
): Promise<any> =>
  doPost(`${getApiUrl()}/comment/create`, {
    commentBody: comment,
    postId: postId,
    userName: userName,
    postType: "",
    taggedUsers: taggedUsers,
  });

export const addReply = (
  sectionId: number,
  commentId: number,
  comment: string,
  userName: string,
  taggedUsers: string[]
): Promise<any> =>
  doPost(`${getApiUrl()}/comment/create/reply`, {
    replyBody: comment,
    commentId: commentId,
    userName: userName,
    taggedUsers: taggedUsers,
    postId: sectionId,
  });

export const updateComment = (
  id: number,
  comment: string,
  userName: string,
  isReply: boolean,
  taggedUsers: string[],
  sectionId: number
): Promise<any> => {
  if (isReply) {
    return doPut(`${getApiUrl()}/comment/update/reply`, {
      id: id,
      userName: userName,
      replyBody: comment,
      taggedUsers: taggedUsers,
      postId: sectionId,
    });
  }
  return doPut(`${getApiUrl()}/comment/update`, {
    id: id,
    userName: userName,
    commentBody: comment,
    taggedUsers: taggedUsers,
    postId: sectionId,
  });
};

export const deleteComment = (id: number, isReply: boolean): Promise<any> => {
  if (isReply) {
    return doDelete(`${getApiUrl()}/comment/delete/reply/${id}`);
  }
  return doDelete(`${getApiUrl()}/comment/delete/${id}`);
};
