import { Brand } from "../../../models/Brand";

export const brandsActions = {
  ADD_BRAND: "ADD_BRAND",
  UPDATE_BRAND: "UPDATE_BRAND",
  SET_BRANDS: "SET_BRANDS",
  SET_EDITABLE_BRAND: "SET_EDITABLE_BRAND",
  RESET_EDITABLE_BRAND: "RESET_EDITABLE_BRAND",
  SET_LOADING: "SET_LOADING",
  SET_MODAL_OPEN: "SET_MODAL_OPEN",
  SET_REGIONS_MODAL_OPEN: "SET_REGIONS_MODAL_OPEN",
  SET_NEW_REGION: "SET_NEW_REGION",
  SET_EDITABLE_REGION: "SET_EDITABLE_REGION",
  SET_DELETE_MODAL_OPEN: "SET_DELETE_MODAL_OPEN",
  SET_MASTER_COUNTRY_LIST: "SET_MASTER_COUNTRY_LIST",
};

export const defaultBrandState = {
  id: 0,
  businessBrandName: "",
  siteTypes: [],
};

export const defaultRegionState = {
  regionId: 0,
  regionName: "",
  businessBrandId: 0,
  countries: [],
  areas: [],
};

export const defaultBrands = {
  brands: [],
  loading: true,
  editableBrand: defaultBrandState,
  editableRegion: defaultRegionState,
  isModalOpen: false,
  isRegionsModalOpen: false,
  isDeleteModalOpen: false,
  masterCountryList: [],
};

export const brandsReducer = (state: any, action: any) => {
  switch (action.type) {
    case brandsActions.SET_BRANDS:
      return { ...state, brands: action.brands };
    case brandsActions.UPDATE_BRAND:
      return {
        ...state,
        brands: state.brands.map((brand: Brand) =>
          brand.id === action.brand.id ? action.brand : brand
        ),
      };
    case brandsActions.SET_EDITABLE_BRAND:
      return { ...state, editableBrand: action.brand };
    case brandsActions.ADD_BRAND:
      return { ...state, brands: [...state.brands, { ...action.brand }] };
    case brandsActions.SET_LOADING:
      return { ...state, loading: action.loading };
    case brandsActions.SET_MODAL_OPEN:
      return { ...state, isModalOpen: action.isModalOpen };
    case brandsActions.SET_REGIONS_MODAL_OPEN:
      return {
        ...state,
        isRegionsModalOpen: action.isRegionsModalOpen,
        isModalOpen: false,
      };
    case brandsActions.RESET_EDITABLE_BRAND:
      return { ...state, editableBrand: defaultBrandState };
    case brandsActions.SET_NEW_REGION:
      return {
        ...state,
        editableRegion: {
          ...defaultRegionState,
          businessBrandId: action.brandId,
        },
      };
    case brandsActions.SET_EDITABLE_REGION:
      let editableRegion = action.region;
      let newState = { ...state, editableRegion: editableRegion };
      return newState;
    case brandsActions.SET_DELETE_MODAL_OPEN:
      return {
        ...state,
        isDeleteModalOpen: action.isDeleteModalOpen,
      };
    case brandsActions.SET_MASTER_COUNTRY_LIST:
      return {
        ...state,
        masterCountryList: action.masterCountryList,
      };
    default:
      return state;
  }
};
