import { McList, McListItem, McMenu } from "@maersk-global/mds-react-wrapper";
import { StaticIcon } from "./icons";

export default function Action({ items }: { items: any[] }) {
  return (
    <McMenu fit="small" trigger="click" position="left-top">
      <StaticIcon icon="ellipsis-vertical" slot="trigger" />
      <McList fit="small">
        {items.map((item, index) => (
          <McListItem key={index} onClick={item.onClick}>
            {item.label}
          </McListItem>
        ))}
      </McList>
    </McMenu>
  );
}
