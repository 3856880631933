export interface ColorScheme {
  [key: string]: string;
}

export const colors: ColorScheme = {
  "mds_brand_appearance_primary_default_text-color": "#00243D",
  "mds_brand_appearance_neutral_subtle_border-color": "#DBDBDB",
  "mds_brand_appearance_neutral_weakest_text-color": "#767676",
  "mds_brand_appearance_neutral_weak_background-color": "#EDEDED",
  "mds_brand_appearance_error_default_text-color": "#B80012",
  "mds_brand_appearance_neutral_inverse_background-color": "#141414",
  "mds_core_button_primary_filled_background-color": "#42B0D5",
  "mds_core_background_primary_weak_color": "#E9F6FC",
  "mds_brand_appearance_warning_default_background-color": "#FFD029",
};
